.cards { margin: 0 20px; padding: 0; list-style: none; max-width: 800px;
	li { padding-top: 200%; height: 0; max-height: 75vh; background: #000; color: #fff; }
}

// 3 Cards Breakpoint

.story { margin: 0 auto; padding: 0; list-style: none; width: 100%; max-width: 1300px; display: grid; grid-gap: 30px; grid-template-columns: 1fr 1fr 1fr;

	.card { display: flex; align-items: flex-end; justify-content: center; height: 700px; width: 413px; border-radius: 10px; position: relative; background: #000; z-index: 10;
				
		.scrim { width: 90%; padding: 5%; }

		.scrim-100 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,1) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-90 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.9) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-80 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.8) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-70 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.7) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-60 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.6) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-50 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.5) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-40 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.4) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-30 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.3) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-20 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.2) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-10 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.1) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }
		.scrim-0 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0) 56%, rgba(0,0,0,0) 100%); border-radius: 5px; }

		.map-scrim { background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,0.8) 56%, rgba(255,255,255,0) 100%); border-radius: 5px; }

		video { position: absolute; right: 0; bottom: 0; height: 100%; width: 100%; z-index: -1; object-fit: fill; border-radius: 5px; }

		.inner { z-index: 100; text-align: left; width: 100%; margin: 0; color: #fff; display: block;
			hr { height: 2px; border: none; background: $red; width: 20%; margin: 0 40% 5px 40%; }
			h2 { text-align: center; line-height: 135%; color: #fff; font-size: 30px; font-weight: 700; 
				span { display: block; opacity: 1; margin: 10px auto; font-size: 12px; font-weight: 500; text-transform: uppercase; font-family: "Gotham SSm A", "Gotham SSm B";  
					a { color: #fff; }
				}
			}
			p,ul,ol { font-size: 16px; font-weight: 400; line-height: 150%; color: #fff; border-left: 2px solid $red; margin: 15px 0; padding: 0 0 0 5%; width: 95%;
				a { color: $light-blue; padding-bottom: 2px; border-bottom: 2px solid $light-blue;}
			}
			ol,ul { list-style: square; padding-left: 12%; width: 88%;}
		}
		.no-graphic { 
			p,ol,ul { border-left: 0; padding-left:0; width: 100%; }
			ol,ul { list-style: square; padding-left: 8%; width: 92%;}
		}
		.map { text-align: center;
			h4 { margin: 55px 0 0 0; font-size: 19px; color: $primary-color; }
			p { padding: 10px 2.5% 15px 2.5%; margin: 0; font-size: 15px; border-left: 0; width: 95%; color: $primary-color; }
			a { padding: 2% 0; display: block; background: $red; font-size: 14px; color: #fff; border-radius: 3px; }
		}
	}
	
	#cover-photo { display: none; }
	.end-card { display: none; background: #fff; align-items: center; justify-content: center; box-shadow: 0 0 0 4px $red; -webkit-box-shadow: 0 0 0 4px $red; -moz-box-shadow: 0 0 0 4px $red; 
		h2 { color: #000; font-size: 23px; font-weight: 800; width: 80%; padding: 0px 10%; margin: 0px 0 20px 0; line-height: 150%;
			a { border-bottom: 2px solid $red; padding-bottom: 2px; }
		}
		hr { height: 1px; border: none; background: #ddd; width: 60%; margin: 50px 20% 50px 20%; }
		.social-links { margin-top: 30px;
			a { display: inline-block !important; margin: 0 5px; background-size: 30px 30px !important; width: 30px !important; height: 30px !important; }
		}
		#mc_embed_signup { margin: 23px auto 0px auto; position: relative; 
			label { display: none; }
			input.email { font-family: "Gotham SSm A", "Gotham SSm B"; border: none; outline: none; width: 70%; border: 3px solid $red; max-width: 300px; border-radius: 3px; padding: 2.5% 5%; height: 25px; color: $primary-color; background: #fff; font-size: 15px; font-weight: 600;  }
			input.email::placeholder { color: #999; font-weight: 300; }
			input.button { margin: 10px 0 0 10%; font-family: "Gotham SSm A", "Gotham SSm B";-webkit-appearance: none; background: $red; border: none; border-radius: 3px; padding: 15px 5%; color: #fff; font-size: 15px; font-weight: 500; width: 80%; display: block; cursor: pointer; box-shadow: 0px 0px 0 2px $red; -webkit-box-shadow: 0px 0px 0 2px $red; -moz-box-shadow: 0px 0px 0 2px $red; -webkit-transition: all 0.4s; transition: all 0.4s; -moz-transition: all 0.4s; }
			input.button:hover { opacity: 0.8; }
		}
	}
	
	.read-more-card { display: block; display: none; padding: 20px 0 40px 0;
		h2 { margin: 20px 0;}
		hr { height: 4px; margin: 30px 0; width: 100%; background: $background;  }
		a.read-more { padding: 4% 0; font-size: 15px;  display: inline-block; padding: 5px 10px; border-radius: 4px; color: $red; font-weight:800; text-align: center; }
		.read-more-stories { margin: 0; width: 95%; padding: 2.5%; display: grid; grid-gap: 5px; grid-template-columns: repeat(auto-fill,minmax(150px,1fr)); grid-auto-flow: column; grid-auto-columns: minmax(150px,1fr); overflow-x: auto;
			.this-story a { display: grid; align-items: flex-end; width: 95%; height: 200px; margin: 2.5%; border-radius: 10px;
				.scrim { align-items: flex-end; background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.7) 56%, rgba(0,0,0,0) 100%); border-radius: 5px;
					.inner h2 {  font-size: 18px; padding: 0; width: 100%; }
				}
			}
		}
	}

}

.desktop-end-of-story { margin: 100px auto;
	
	.subscribe-cta { max-width: 1300px; border-top: 1px dashed #000; border-bottom: 1px dashed #000; padding: 20px 0; margin: 40px auto 0 auto;  display: grid; grid-template-columns: 1fr 1fr; grid-gap: 40px; align-items: center; justify-items: flex-start;
		h1 { font-size: 36px;  text-align: left; align-self: flex-start;
			span { display: block; font-size: 18px; line-height: 155%; font-weight: 400; margin-top: 10px; font-family: "Gotham SSm A", "Gotham SSm B"; }
		}
		#mc_embed_signup { width: 100%; position: relative;
			label { display: none; }
			input.email {   width: 92%; font-size: 17px; padding: 4% ; border-radius: 50px; border: 2px solid $red; }
			input.button { cursor: pointer; position: absolute; top: 13px; right: 13px; font-size: 17px; font-weight: 900; width: 150px; background: $red; color: #fff; padding: 15px 10px; border-radius: 50px; border: none; }
		}
	}
	
}

// 2 Cards Breakpoint

@media (min-width: 900px) and (max-width:1300px) {

	.story { margin: 0 auto; padding: 0; list-style: none; width: 100%; max-width: 900px; grid-gap: 30px; grid-template-columns: 1fr 1fr;
//		.card { height: 640px; width: 360px; margin: 20px; }
	}
	
	.desktop-end-of-story { margin: 100px auto;
	
		.subscribe-cta { padding: 40px 5%; margin: 40px auto 0 auto;  display: grid; grid-template-columns: 1fr 1fr; align-items: center; justify-items: flex-start;
			#mc_embed_signup { 
				input.button { top: 10px;  }
			}
		}
	
	}
	
	
}

// 1 Card Breakpoint

@media screen and (max-width: 900px) { 

	.story { scroll-snap-type: y mandatory;	overflow-y: scroll; margin-top: 7vh; height: 93vh; grid-template-columns: 1fr; grid-gap: 10px;
		
		#cover-photo { display: flex; }
		.card { width: 90vw; height: 75vh; scroll-snap-align: start; margin: 0 5vw 5vw 5vw; float: none; }
		.end-card { background: #fff; box-shadow: 0 0 0 0px #fff; -webkit-box-shadow: 0 0 0 0px #fff; -moz-box-shadow: 0 0 0 0px #fff; display: flex;
			h2 { font-size: 20px; padding-top: 0px; }
		}
		.social-card { align-items: flex-start; }
		.read-more-card { display: block; 
			h2 { margin: 10px 0; padding-top:0; }
			a.read-more { margin: 10px 0 10px 0; }
		}
		
	}
	
	.desktop-end-of-story { display: none; }

}