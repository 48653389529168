.title { margin: 20px auto; max-width: 1190px;  
	span.location { background-size: 20px 20px; font-size: 17px; font-weight: 600; padding-left: 26px; }
	h1 { font-size: 74px; margin: 10px auto 0 auto; max-width: 950px; text-wrap: balance; }
	h3 { font-size: 24px; max-width: 800px; margin: 20px auto; text-wrap: balance; }
	p { margin: 10px auto; font-size: 17px; font-weight: 400; }
	img { margin: 10px auto 0 auto; width: 100%; max-width: 1000px; border-radius: 4px; }
}

.article-title { margin: 60px auto 10px auto; max-width: 1300px; width:90%;
	span.location { background-size: 20px 20px; font-size: 17px; font-weight: 600; padding-left: 26px; }
	h1 { font-size: 62px; margin: 10px auto 0 auto; max-width: 1000px;  text-wrap: balance;  }
	h3 { font-size: 21px; font-weight: 400; max-width: 900px; margin: 20px auto;   text-wrap: balance; }
	.meta { display: flex; gap: 20px; align-items: center; justify-content: center;
		span {font-size: 14px; display: inline; }
		img.profile-pic { width: 48px; border-radius: 48px; border: 1px solid $darkbackground; }
	}
	iframe { margin-top: 40px; }
}

@media (min-width: 960px) and (max-width:1300px) {

	.title { margin-top: 100px; width: 95%; }
	.article-title { margin-top: 20px; width: 90%; }
	
}


@media screen and (max-width: 960px) { 

	.title { display: none; }
	.article-title { margin-top: 100px; width: 90%; 
		h1 { font-size: 40px; }
	}

}