/*! Custom Stuff
---------------------------------------------- */

.main-carousel { margin: 0 auto; width: 100%; text-align: center; background: #f9f9f9; }

.carousel-cell { height: 88vh; width: 49.5vh; margin: 0 1vw; opacity: 0.2; align-items: flex-end; display: flex; cursor: pointer;
	.scrim { width: 90%; padding: 5%; border-radius: 5px; }

	.scrim-100 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,1) 56%, rgba(0,0,0,0) 100%); }
	.scrim-90 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.9) 56%, rgba(0,0,0,0) 100%); }
	.scrim-80 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.8) 56%, rgba(0,0,0,0) 100%); }
	.scrim-70 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.7) 56%, rgba(0,0,0,0) 100%); }
	.scrim-60 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.6) 56%, rgba(0,0,0,0) 100%); }
	.scrim-50 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.5) 56%, rgba(0,0,0,0) 100%); }
	.scrim-40 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.4) 56%, rgba(0,0,0,0) 100%); }
	.scrim-30 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.3) 56%, rgba(0,0,0,0) 100%); }
	.scrim-20 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.2) 56%, rgba(0,0,0,0) 100%); }
	.scrim-10 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0.1) 56%, rgba(0,0,0,0) 100%); }
	.scrim-0 { background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,0) 56%, rgba(0,0,0,0) 100%); }

	.inner { z-index: 100; text-align: left; width: 100%; margin: 0; color: #fff;
		hr { height: 2px; border: none; background: $red; width: 20%; margin: 0 40% 5px 40%; }
		h1 { text-align: center; line-height: 135%; color: #fff; font-size: 3.5vmin; font-weight: 700; 
			span { display: block; opacity: 1; margin: 10px auto; font-size: 1.5vmin; font-weight: 500; text-transform: uppercase;  
				a { color: #fff; }
			}
		}
		p,ul,ol { font-size: 2vmin; font-weight: 400; line-height: 150%; color: #fff; border-left: 2px solid #FF3D00; margin: 15px 0; padding: 0 0 0 5%; width: 95%;
			a { color: $light-blue; padding-bottom: 2px; border-bottom: 2px solid $light-blue;}
		}
		ol,ul { list-style: square; padding-left: 12%; width: 88%;}
	}
	
	.map { z-index: 100; text-align: center; width: 90%; margin: 5% 5% 10% 5%;
		h4 { margin: 0; font-size: 19px; color: $primary-color; }
		p { padding: 10px 0 15px 0; margin: 0; font-size: 15px; border-left: 0; width: 95%; color: $primary-color; }
		a { padding: 4% 8%; border-radius: 5px; display: inline-block; background: $red; font-size: 14px; color: #fff; }
	}
	
}

.end-cell { background: #fff; background: radial-gradient(circle, rgba(255,255,255,1) 20%, rgba(235,235,235,1) 100%); justify-content: center; align-items: center;
	.item { width: 80%; padding: 10%;
		h3 { font-weight: 600; font-size: 30px; line-height: 130%; text-align: left; }
		#mc_embed_signup { margin: 23px auto 0px auto; position: relative; 
			label { display: none; }
			input.email { font-family: "Gotham SSm A", "Gotham SSm B"; border: none; outline: none; width: 90%; border-radius: 3px; padding: 5%; -webkit-appearance: none;  color: $primary-color; background: #fff; font-size: 13px; font-weight: 600; -webkit-box-shadow: 0 0 0 3px $red; box-shadow: 0 0 0 3px $red; -moz-box-shadow: 0 0 0 3px $red; }
			input.email::placeholder { color: #999; font-weight: 300; }
			input.button { position: absolute; top: 0; right: 0; font-family: "Gotham SSm A", "Gotham SSm B"; -webkit-appearance: none; background: $red; border: none; border-radius: 0 3px 3px 0; padding: 5%; color: #fff; text-transform: uppercase; font-size: 13px; font-weight: 500; display: inline-block; cursor: pointer; -webkit-box-shadow: 0 0 0 3px $red; box-shadow: 0 0 0 3px $red; -moz-box-shadow: 0 0 0 3px $red; -webkit-transition: all 0.4s; transition: all 0.4s; -moz-transition: all 0.4s; }
			input.button:hover { background: #DD2C00; color: #fff; -webkit-box-shadow: 0 0 0 3px #DD2C00; box-shadow: 0 0 0 3px #DD2C00; -moz-box-shadow: 0 0 0 3px #DD2C00; }
		}
	}
}


@media screen and (max-width: 960px) { 

	.carousel-cell { height: 76vh; width: 42.75vh; margin: 0 1vw; opacity: 0.2; align-items: flex-end; display: flex; cursor: pointer; opacity: 1;
		.inner { 
			h1 { font-size: 26px; 
				span { font-size: 12px; }
			}
			p,ul,ol { font-size: 17px; }
		}
	}
	
	.end-cell { justify-content: center; align-items: center; 
		.item h3 { font-size: 24px; }
	}

}


/*! Flickity Options
---------------------------------------------- */

.is-selected { 
	opacity: 1;
	cursor: default;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
//  cursor: move;
//  cursor: -webkit-grab;
//  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
//  cursor: -webkit-grabbing;
//  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

@media screen and (max-width: 960px) { 
	.flickity-button:disabled {
		opacity: 0;
	}
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -35px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}