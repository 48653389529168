.articles {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 95%;
	max-width: 1300px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 32px;
	text-align: left;
	align-items: flex-start;
	justify-items: flex-end;

	li {
		img {
			width: 100%;
			border-radius: 3px;
		}

		a {
			color: #000;
		}

		h2 {
			font-size: 26px;
			font-weight: 900;
			margin: 10px 0;
		}

		p {
			font-size: 15px;
			font-weight: 300;
		}
	}

}

.article {
	width: 95%;
	max-width: 1300px;
	margin: 60px auto;
	text-align: left;

	p,
	ul,
	ol {
		font-size: 17px;
		font-weight: 400;
		line-height: 175%;
		margin: 20px auto;
		max-width: 800px;

		a {
			text-decoration: underline;
		}
	}

	p:has(img) {
		max-width: 1000px;
	}

	h2 {
		font-size: 36px;
		font-weight: 700;
		margin: 60px auto 20px;
		max-width: 800px;
	}

	.three-pack {
		display: flex;
		gap: 10px;

		p {
			display: flex;
			gap: 10px;
		}

		img {
			border-radius: 4px;
			width: 100%;
		}
	}

	img {
		width: 100%;
	}

	p.caption {
		margin: -15px auto 30px 0;
		font-size: 14px;
	}

}


@media screen and (max-width: 800px) {

	.articles {
		grid-template-columns: 1fr;
	}

	.article {
		width: 90%;
	}

}