.categories { position: relative; max-width: 1100px; margin: 80px auto 0 auto; display: grid; grid-template-columns: 1fr 1fr; align-items: center; justify-items: flex-end;
	h4 { justify-self: flex-start; }
	a#toggle-categories { color: #000; font-weight: 600; padding-right: 20px; display: inline-block; background: url('../images/icon-dropdown.png') no-repeat 100% 50%; background-size: 10px 10px; }
	ul { display: none; background: #fff; position: absolute; top: 50px; right: -40px; list-style: none; margin: 0px auto 0px auto; padding: 20px; width: 160px; border-radius: 30px; 
		li {  
			a { font-size: 16px; font-weight: 500; color: $primary-color; display: inline-block; }
			a:hover { color: $red; }
		}
		li.active a { color: $red; font-weight: 600;}
	}
}

@media (min-width: 0px) and (max-width:1300px) {

	.categories { width: 95%; 
		ul { right: -10px; }
	}

}

@media (min-width: 0px) and (max-width:900px) {

	.categories { width: 90%; }
}

