.page { 
	
	h2 { margin-top: 40px; }
	p.intro { font-size: 23px; }
	ul,ol,p { font-weight: 400; line-height: 200%;
		a { color: $blue; padding-bottom: 2px; border-bottom: 1px solid $blue; font-weight: 400; }
	}
	
	form {
		
		.field { display: block; margin-bottom: 20px;
			label { font-weight: 500; }
			textarea,input { border-radius: 2px; border: 1px solid #ddd; display: block; width: 96%; padding: 2%; font-size: 16px; margin-top: 5px;}
			textarea { min-height: 60px;}
			button { background: $blue; padding: 20px; color: #fff; border: none; border-radius: 5px; font-size: 16px; font-weight: 600; cursor: pointer; }
		}
		
	}
	
}