.inline-newsletter { border-radius: 4px; max-width: 1300px; width: 95%; margin: 10px auto 20px auto; background: $midbackground; display: none;
	.inner { display: flex; justify-content: space-between; align-items: center; padding: 10px 20px; flex-grow: 1; gap: 30px;
		p { font-weight: 400; font-size: 15px; 
			strong { margin-right: 10px; }
		}
		#mc_embed_signup { flex: 1; }
		.fields { display: flex; margin: 0 auto; flex-grow: 1; justify-content: flex-end;
			.email { padding: 15px 15px; font-size: 16px; border: 1px solid $red; border-radius: 4px; flex: 1; max-width: 350px; }
			.button { color: #fff; background: $red; font-weight: 500; border-radius: 4px; cursor: pointer; margin-left: 10px; padding: 16px 15px; border: none; font-size: 16px; -webkit-appearance: none; -moz-appearance: none;appearance: none;  }
		}
	}

}
@media screen and (max-width: 960px) { 
	.inline-newsletter { display: none; }
}





.bubble-newsletter { z-index: 120000; position: fixed; bottom: 0; right: 100px; border-radius: 4px 4px 0 0; width: 345px; padding: 0 20px 30px 20px;  background: #000; display: none;
	background: rgba(0, 0, 0, 0.75);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(9.9px);
	-webkit-backdrop-filter: blur(9.9px);
	border-top: 8px solid $red; 
	h3 { text-transform: uppercase; font-size: 13px; color: #fff; cursor: pointer; 
		span { font-size: 18px; transform: rotate(90deg); display: inline-block; float: right; }
	}
	h3:hover { opacity: 0.8; }
	p { font-weight: 400; font-size: 16px; color: #fff; margin: 30px auto; }
	.form-toggle { background: $red; font-weight: 500; padding: 15px 0; width: 80%; margin: 0 auto; color: #fff; display: block; border-radius: 4px; }
	.form { display: none; 
	#mc_embed_signup { flex: 1; }
		.fields { display: flex; flex-direction: column; gap: 10px; margin: 0 auto; 
			.email { padding: 15px 15px; font-size: 16px; border-radius: 4px;}
			.button { color: #fff; background: $red; font-weight: 800; border-radius: 4px; cursor: pointer; padding: 16px 15px; border: none; font-size: 16px; -webkit-appearance: none; -moz-appearance: none;appearance: none;  }
		}
	}
}
.bubble-newsletter-collapsed { padding-bottom: 0;
	h3 span { transform: rotate(270deg); }
	.inside { display: none; }
}
@media screen and (max-width: 960px) { 
	.bubble-newsletter { width: 90%; right: 0; padding: 0 5% 30px 5%; border-radius: 0; 
		p { text-wrap: balance; }
	}
	.bubble-newsletter-collapsed { padding-bottom: 0; }
}



.index-newsletter { border-radius: 5px; padding: 1px; max-width: 1300px; margin: 0 auto 20px auto; width: 90%; background: rgb(213,0,0); background: -moz-radial-gradient(circle, rgba(213,0,0,1) 0%, rgba(96,52,67,1) 100%); background: -webkit-radial-gradient(circle, rgba(213,0,0,1) 0%, rgba(96,52,67,1) 100%); background: radial-gradient(circle, rgba(213,0,0,1) 0%, rgba(96,52,67,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d50000",endColorstr="#603443",GradientType=1);
	.inner { background: $background; padding: 80px 30px 80px 60px;  border-radius: 5px; text-align: left; display: flex; justify-content: space-between; align-items: center;
		h2 { margin: 0; color:#D30202; font-size: 56px; font-weight: 800; }
		p { font-weight: 400; font-size: 24px; }
		.fields { display: flex; margin: 0 auto;
			.email { padding: 10px 15px; font-size: 16px; border: 1px solid $red; max-width: 300px; border-radius: 4px; }
			.button { color: #fff; background: $red; font-weight: 800; border-radius: 4px; cursor: pointer; margin-left: 10px; padding: 11px 15px; border: none; font-size: 16px; -webkit-appearance: none; -moz-appearance: none;appearance: none;  }
		}
		#mc_embed_signup { max-width: 400px; }
	}
	.email-mock { max-width: 400px;}

}


.newsletter { background: #fff; background: rgba(255, 255, 255, 0.95); height: 100%; width: 95%; padding: 2.5%; position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: block; z-index: 99999999; overflow: auto;text-align:center; display: none;
	.inner { background: $background; max-width: 450px; padding: 5%; width: 90%; margin: auto; z-index: 999999999; border-radius: 3px; position: relative; box-shadow: 0 10px 20px #ddd; -webkit-box-shadow: 0 10px 20px #ddd; -moz-box-shadow: 0 10px 20px #ddd;
		h2 { margin: 0 auto; font-size: 44px; max-width: 500px; }
		p { margin: 20px auto; max-width: 420px; }
		a#close-newsletter { background: url('../images/icon-close.png'); background-size: 24px; height: 24px; width: 24px; text-indent: -9999em; display: inline-block; position: absolute; top: 5%; right: 5%; }
		#mc_embed_signup { margin: 23px auto 0px auto; position: relative; 
			label { display: none; }
			input.email { font-family: "Gotham SSm A", "Gotham SSm B"; border: none; outline: none; width: 90%; border: 3px solid $red; max-width: 300px; border-radius: 3px; padding: 2.5% 5%; height: 25px; color: $primary-color; background: #fff; font-size: 15px; font-weight: 600; }
			input.email::placeholder { color: #999; font-weight: 300; }
			input.button { margin-top: 20px; font-family: "Gotham SSm A", "Gotham SSm B";-webkit-appearance: none; background: $red; border: none; border-radius: 3px; padding: 15px 5%; color: #fff; text-transform: uppercase; font-size: 13px; font-weight: 500; display: inline-block; cursor: pointer; box-shadow: 0px 0px 0 2px $red; -webkit-box-shadow: 0px 0px 0 2px $red; -moz-box-shadow: 0px 0px 0 2px $red; -webkit-transition: all 0.4s; transition: all 0.4s; -moz-transition: all 0.4s; }
			input.button:hover { background: #000; color: #fff; }
		}
		.email-mock { max-width: 250px; margin-top: 30px; }
	}
}

@media screen and (max-width: 800px) { 

	.newsletter { 
		.inner { padding-bottom: 30%;
			h2 { margin-top: 50px; font-size: 30px; }
			p { font-size: 16px; }
			a#close-newsletter { top: 2.5%; right: 5%; width: 20px; height: 20px; background-size: 20px; }
		}
	}
	

	
	
	.index-newsletter { margin-top: 100px;
		.inner { flex-direction: column; text-align: center; padding: 5%; gap: 0;
			h2 { font-size: 38px; }
			p { font-size: 19px; }
			.fields { justify-content: center;  flex-direction: column;
				.email { width: 100%; padding: 10px; max-width: 250px; }
				.button { width: 100%; padding: 10px; margin: 5px 0 0 0; border: 1px solid $red; max-width: 270px; }
			}
		}
		.email-mock { margin-top: 30px; max-width: 150px; }
	}

}