.arrow { color: #fff; font-weight: 900; font-size: 25px; text-align:center; display: block; margin: 0; line-height: 100%; position: absolute; bottom: 10px; left: 0; width: 100%; 
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
	display: none; 
}

.bounce-3 {
    animation-name: bounce-3;
    animation-timing-function: ease;
}
@keyframes bounce-3 {
    0%   { transform: translateY(0); }
    30%  { transform: translateY(-10px); }
    50%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}

@media screen and (max-width: 800px) { 
 
	.arrow { display: block; }
 
}