.header { border-bottom: 1px solid $darkbackground; box-shadow: 0 1px 0 0px #fff; height: 90px; margin: 0 auto; width: 95%; max-width: 1300px; position: relative; display: flex; align-items: center;   justify-content: space-between;	background: $background; z-index: 1000;
	h1 {  font-weight: 900; font-size: 23px; letter-spacing: 10px; text-transform: uppercase; text-align: center; justify-self: flex-start; 
		a { color: $primary-color; }
	}
	ul { text-align: left; margin: 0; padding: 0; list-style: none; display: flex; gap: 20px;
		li { 
			a { font-size: 15px; font-weight: 400; color: $primary-color;  }
		}
	}
	ul.social-links { text-align: right;
		li { margin: 0 0 0 20px; text-align: left; }
	}
	#toggle-mobile-menu { display: none; }
}

@media screen and (max-width: 960px) { 

	.header { height: 7vh; position: fixed; top: 0; left: 0; width: 100%; padding-top: 0; 
		h1 { font-size: 1.2em; text-align:left; width: 100%;
			a { margin: 0 0 0 2.5vh;  }
		}
		ul { background: #fff; position: absolute; top: 7vh; width: 100%; padding-bottom: 20px; display: none; margin-top:-1px; border-bottom: 1px solid #ddd; z-index: 12000;
 			li { display: block; text-align: center;
				a { display: block; padding:5px 0;}
			}
		}
		#toggle-mobile-menu { display: block; margin-right: 2.5vh;
			a { width: 18px; height: 18px; padding: 10px 0 10px 10px; display: inline-block; background: url('../images/mobile-menu.png') no-repeat; background-size: 18px 18px; background-position: 50%; text-indent: -999999px;  }
		}
	}

}