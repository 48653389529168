$primary-color: #222;
$secondary-color: #777;
$tertiary-color: #bbb;
$blue: #2962FF;
$red: #D50000;
$light-red: #ffebee;
$yellow: #F2BA42;
$light-blue: #eaeffe;
$border: #ECEFF1;
$background: #FAF5EF;
$midbackground : #EDE8E2;
$darkbackground: #CCC4BB;

body {
	font-family: "Gotham SSm A", "Gotham SSm B";
	text-align: center;
	color: $primary-color;
}

.clear {
	clear: both;
}

a {
	color: $red;
	text-decoration: none;
}

h1 {
	font-size: 62px;
	font-weight: 800;
	font-family: "Chronicle Display A", "Chronicle Display B";
}

h2 {
	font-size: 60px;
	font-weight: 700;
	font-family: "Chronicle Display A", "Chronicle Display B";
}

h3 {
	line-height: 160%;
	font-size: 26px;
	font-weight: 500;
}

p,
ul,
ol {
	font-size: 19px;
	line-height: 165%;
}

.splash {
	margin: 60px auto;
	max-width: 1190px;

	h1 {
		font-size: 58px;
		margin-bottom: 0;
	}

	p {
		max-width: 800px;
		margin: 10px auto;
		font-size: 24px;
		font-weight: 500;

		a {
			display: block;

			span {
				padding-bottom: 2px;
				border-bottom: 2px solid $red;
			}
		}
	}
}

.location {
	width: 14px;
	height: 14px;
	background: url('../images/icon-marker.png') no-repeat;
	background-size: 14px 14px;
	padding-left: 20px;
	font-size: 13px;
	font-weight: 700;
}

a#instagram {
	text-indent: -9999px;
	display: block;
	width: 24px;
	height: 24px;
	background: url('../images/icon-instagram.png') no-repeat;
	background-size: 22px 22px;
}

a#facebook {
	text-indent: -9999px;
	display: block;
	width: 24px;
	height: 24px;
	background: url('../images/icon-facebook.png') no-repeat;
	background-size: 22px 22px;
}

a#twitter {
	text-indent: -9999px;
	display: block;
	width: 24px;
	height: 24px;
	background: url('../images/icon-twitter.png') no-repeat;
	background-size: 22px 22px;
}


@media screen and (max-width: 1000px) {

	.splash {
		width: 90%;
		margin: 100px 5% 10vh 5%;

		h1 {
			font-size: 40px;
		}

		p {
			font-size: 18px;

			a {
				display: inline;

				span {
					border-bottom: 1px solid $red;
					padding-bottom: 1px;
				}
			}
		}
	}

	.mailchimp {
		#mc_embed_signup {
			input.button {
				font-size: 13px;
			}
		}
	}


}