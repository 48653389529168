.browse-stories { text-align: left; list-style: none; margin: 0 auto; padding: 0; max-width: 1100px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 40px;
	li { 
		h2 { font-size: 24px; font-weight: 800; margin: 10px 0; 
			a { color: $primary-color; }
		}
		p { font-size: 15px; font-weight: 300; margin: 0; 
			a { color: $primary-color; }
		}
		img { border: none; width: 100%; max-width: 100%; border-radius: 10px; }
	}
}

.browse-stories-small { width: 95%; max-width: 1300px; margin: 80px auto 0 auto; text-align: left; position: relative;
	h3 { font-weight: 700; font-size: 18px; }
	a.all-stories-link { position: absolute; top: 3px; right: 0; color: $primary-color; font-weight: 700; font-size: 18px; }
	.browse-stories { max-width: 1300px; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 30px; margin-top: 20px;
		li { 
			h2 { font-size: 21px; text-align: center; }
		}
	}
	
}

// 2 Cards Breakpoint

@media (min-width: 900px) and (max-width:1300px) {

	.browse-stories {  margin: 0 auto; width: 95%; grid-template-columns: 1fr 1fr;
		li a { width: 90% ;}
	}
	
	.browse-stories-small .browse-stories { margin: 0 auto; width: 100%; }
	
}

@media (min-width: 0px) and (max-width:900px) {

	.browse-stories { width: 90%; grid-template-columns: 1fr; }

}

