.footer { padding: 50px 0; 
	ul.social-links { list-style: none; margin: 20px 0; padding: 0; 
		li { display: inline-block; margin: 0 10px; }
	}
	p { margin: 0px 0; color: #000; font-size: 15px; 
		span { margin: 0 5px; }
		a { color: #000; border-bottom: 2px solid $light-red; padding-bottom: 2px; }
	}
}

