.things-to-do { max-width: 1000px; text-align: center; margin: 0 auto;
	
	.sidebar {  width: 25%; float: left; text-align: left; padding: 40px 0 0 0;
		h4 { font-size: 14px; font-weight: 800; padding-left: 10px; }
		a#mobile-toc { display: none; }
		ul { list-style: none; margin: 0; padding: 0; 
			li a { display: block; padding: 4px 0 4px 10px; font-size: 15px; color: $primary-color; }
			li a:hover { background: #fdf4f3; color: $red; }
			li a.active { background: #fdf4f3; color: $red; }
		}
	}
	
	.main { width: 65%; float: right; padding: 50px 0 50px 10%; box-shadow: -1px 0px 0px $border; -moz-box-shadow: -1px 0px 0px $border; -webkit-box-shadow: -1px 0px 0px $border; text-align: left;
		
		h1 { margin-top: 0; }
		h2 { font-size: 44px;}
		p { font-size: 17px; }

	 	h2.subtitle { margin: 0; padding: 10px 0; background: $background; }
		p.subtitle { margin-top: 15px; }
		hr { background: $border; border: none; height: 1px; }
		
		ul { list-style: none; margin: 0; padding: 0; 
			li { padding-top: 20px;
				h3 { margin: 0; padding: 10px 0; background: $background;
					span { background: $red; width: 45px; height: 45px; text-align: center; display: inline-block; color: #fff; margin-right: 5px; border-radius: 100%; }
				}
				img { width: 100%; margin-top: 20px; }
				p.address { color: $secondary-color; font-style: italic;
					a { color: $secondary-color; text-decoration: underline; }
				}
			}
		}

		p.intro { font-size: 21px; }
		p.meta { font-size: 15px;  
			span { margin: 0 5px; font-weight: 800; }
		}
		
	}
	
}

@media screen and (max-width: 800px) { 

	.things-to-do { 
		
		.main { float: none; width: 90%; padding: 5%;  box-shadow: -1px 0px 0px $background; -moz-box-shadow: -1px 0px 0px $background; -webkit-box-shadow: -1px 0px 0px $background; 
			
			h1 { font-size: 44px; }
			h2.subtitle { padding-top: 70px;}
			ul li p.address { font-size: 13px; }
		
		}
		
		.sidebar { margin: 7vh 0 0 0; padding: 0; position: relative; float: none; width: 100%; background: $background; z-index: 120000;
		
			a#mobile-toc { line-height: 150%; border-bottom: 1px solid $border; border-top: 1px solid $border; color: #333; text-align: center; font-size: 13px; padding: 10px 0; height: 20px; font-weight: 500; display: block; }
			h4 { display: none; }
			.table-of-contents { display: block; text-align:left; display: none; background: #fff; position: absolute; padding: 20px 5%; width: 90%; }
	
		}
	
	
	}

}